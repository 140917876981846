<template>
  <div class="page">
    <TheHeader/>
    <router-view/>
    <TheFooter/>
  </div>
</template>

<script>
import TheHeader from "@/widgets/TheHeader";
import TheFooter from "@/widgets/TheFooter";
import {mapMutations} from "vuex";
export default {
  name: 'App',
  components: {
    TheFooter,
    TheHeader
  },
  mounted() {
    window.addEventListener('resize', this.onWindowResize);
    this.onWindowResize()
  },
  methods:{
    ...mapMutations(['updateWindowSize']),
    onWindowResize(){
      const isMobile = document.documentElement.clientWidth < 768;
      const isTablet = document.documentElement.clientWidth >= 768 && document.documentElement.clientWidth < 1220;
      const isDesktop = document.documentElement.clientWidth >= 1220;
      this.updateWindowSize({isMobile, isTablet, isDesktop})
    },
  }
}
</script>

<style lang="stylus">
.page
  min-height 100vh
  display flex
  flex-direction column
  > *:nth-child(2)
    flex 1

</style>

