<template>
  <div class="contact-list">
    <item-icon-base class="contact-list__address">
      <template v-slot:icon><IconBase type="point"/></template>
      <template v-slot:content>г. МОСКВА, ш-се Волоколамское, д. 108, пом. 8Ц</template>
    </item-icon-base>

    <div class="contact-list__tel">
      <item-icon-base v-if="hasTel">
        <template v-slot:icon><IconBase type="tel"/></template>
        <template v-slot:content>
          <div class="contact-list__phone-container">
            <link-base>
              <TelPrimary/>
            </link-base>

            <link-base>
              <a href="tel:+79104408463">+7 (910) 440 8463</a>
            </link-base>
          </div>
        </template>
      </item-icon-base>
      <item-icon-base class="contact-list__email">
        <template v-slot:icon><IconBase type="mail"/></template>
        <template v-slot:content><link-base><MailPrimary/></link-base></template>
      </item-icon-base>
    </div>
  </div>
</template>

<script>
import ItemIconBase from "@/shared/ui/ItemIconBase";
import IconBase from "@/shared/ui/IconBase";
import TelPrimary from "@/features/TelPrimary";
import MailPrimary from "@/features/MailPrimary";
import LinkBase from "@/shared/ui/LinkBase";
export default {
  name: "ContactList",
  components: {LinkBase, MailPrimary, TelPrimary, IconBase, ItemIconBase},
  props:{
    hasTel:{
      type: Boolean,
      default(){
        return true
      }
    }
  }
}
</script>

<style lang="stylus">
.contact-list
  color inherit
  &__address > .item__content
    max-width 240px
  &__phone-container
    display flex
    align-items center
    width 100%
    min-width 240px
    gap: 15px
  &__tel
    display flex
    justify-content end
    gap 25px
    > *:first-child
      min-width fit-content
      display flex
      justify-content space-between
</style>