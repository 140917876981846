<template>
  <div class="item">
    <div class="item__icon"><slot name="icon"/></div>
    <div class="item__content"><slot name="content"/></div>
  </div>
</template>

<script>
export default {
  name: "ItemIconBase"
}
</script>

<style lang="stylus" scoped>
.item
  display flex
  align-items center
  gap 5px
  color inherit
  &__content
    text-align left
</style>