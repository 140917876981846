<template>
<!--  :coords="coordsTemplate(addressList[0].coords)"-->
<!--  :behaviors="behaviors"-->
<!--  :show-all-markers="showAllMarkers"-->
  <section v-show="addressList.length" id="partner" class="section section--pb-none section--grey">
  <div class="wrap">
    <h2 class="section__title">Адреса терминалов <br> партнера</h2>
  </div>
  <div class="address__container">
    <yandex-map
        v-if="addressList.length"
        :ymap-class="`address__map`"
        :coords="addressList[0].coords"
        :settings="settings"
        :controls="controls"
        :use-object-manager="useObjectManager"
        :object-manager-clusterize="objectManagerClusterize"
      :show-all-markers="showAllMarkers"
        :options="{maxZoom:10, minZoom:3}"
    >
  <!--    :cluster-name="`Новосибирск`"-->
  <!--    :balloon-template="balloonTemplate(item.terminal_address)"-->
      <ymap-marker
          v-for="(item, index) in addressList" :key="index"
          :marker-id="index"
          :coords="item.coords"
          :icon="markerIcon"
          :cluster-name="`city`"
          :balloon-template="balloonTemplate(item.terminal_address)"
      />
  <!--    <ymap-marker-->
  <!--        marker-id="124"-->
  <!--        :coords="[55.826655, 37.427372]"-->
  <!--        :icon="markerIconMain"-->
  <!--        :balloon-template="balloonTemplate('г. МОСКВА, ш-се Волоколамское, д. 108, пом. 8Ц')"-->
  <!--        :use-object-manager="useObjectManager"-->
  <!--        :show-all-markers="showAllMarkers"-->
  <!--        :cluster-name="`Новосибирск`"-->
  <!--    />-->
    </yandex-map>
  </div>
  </section>
</template>

<script>
import { yandexMap, ymapMarker} from 'vue-yandex-maps'
import {apiGetAddress} from "@/shared/api/apiAddress";
export default {
  name: "AddressMap",
  components:{yandexMap, ymapMarker},
  computed: {
  },
  data(){
    return{
      settings: {
        apiKey: '2f528c16-7dbf-4d50-80a3-0905fb711210',
        lang: 'ru_RU',
        coordorder: 'latlong',
        enterprise: false,
        version: '2.1',
      },
      controls: ['zoomControl'],
      // behaviors: ['drag'],
      useObjectManager: true,
      objectManagerClusterize: true,
      addressList: [],
      showAllMarkers: true,
      markerIcon: {
        layout: 'default#imageWithContent',
        imageHref: require('@/shared/assets/image/map-marker.svg'),
        imageSize: [50, 50],
        imageOffset: [-25, -50],
      },
      markerIconMain: {
        layout: 'default#imageWithContent',
        imageHref: require('@/shared/assets/image/map-marker--main.svg'),
        imageSize: [50, 50],
        imageOffset: [-25, -50],
      },
    }
  },
  mounted() {
    this.getCoords()
  },
  methods:{
    balloonTemplate: function () {
      return `
        <div class="address__balloon">
<!--          <h1 class="red">Hi, everyone!</h1>-->
          <p>Для уточнения адреса позвоните по телефону <a class="link-base" href="tel:+74957991485">+7 (495) 799 14 85</a></p>
        </div>
      `
    },
//     coordsTemplate: function (string){
//       const array = []
//       console.log(string)
//       return array.push(string)
//     },

    getCoords: async function (){
      const response = await apiGetAddress()
      const statusError = response.status
      if (statusError) {
        // есть ошибка
        this.noteError = this.handleError(statusError)
      } else {
        // нет ошибок
        // const result = response.result.filter(item => item.coords[0] !== 'Не нашел')
        this.addressList = response.result
      }
    }
  }
}
</script>

<style lang="stylus">
.address__map
  height 400px
  width 100%
.address__balloon
  //position fixed
  //left 0
  //top 0
  //bottom 0
  background-color #fff
</style>