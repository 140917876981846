<template>
  <button
      :class="[
          'button-base',
          {
            'button-base--disabled' : isDisabled,
            'button-base--loader' : isLoading,
          }]"
  >
    <IconLoader v-if="isLoading"/>
    <slot v-else></slot>
  </button>
</template>

<script>
import IconLoader from "@/shared/ui/IconLoader";
export default {
  name: "ButtonBase",
  components: {IconLoader},
  props:{
    isDisabled:{
      type: Boolean,
      default(){
        return false
      }
    },
    isLoading:{
      type: Boolean,
      default(){
        return false
      }
    },
    isButtonIcon:{
      type: Boolean,
      default(){
        return false
      }
    },
    isButtonIconSmall:{
      type: Boolean,
      default(){
        return false
      }
    },
  }
}
</script>

<style lang="stylus">
@import "@/shared/styl/variables.styl"
.button-base
  text-align center
  cursor pointer
  &:active
    transform scale(0.99)
  &--disabled
    opacity .2
    pointer-events none
  &--loader
    pointer-events none
</style>